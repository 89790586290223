import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [data, setData] = useState("");
  const baseURL = "https://candidatesearch.workerservices.workers.dev"
  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setData(response.data);
    });
  }, [])
  return (
    <div className="App">
      <h1><center><b>User Details</b></center></h1>
      <table className='tbl-head'>
        <th>
          EmailId
        </th>
        <th>
          TotalScores
        </th>
        <th>
          UserScore
        </th>
        <th>
          Percentage
        </th>
        {data.data && data.data.length > 0 ? data.data.map((guest) => {
          return (
            <>
              <tr><td>{guest.emailId}</td>
                <td>{guest.totalScore}</td>
                <td>{guest.userScore}</td>
                <td>{guest.percentage}</td></tr>

            </>
          )
        }) : ""}
      </table>
    </div>
  );
}

export default App;
